<template>
    <div class="segments">
      <a-row class="d-flex align-items-center mb-4" style="margin: -30px; padding: 22px 30px; background: #fff">
        <a-col :span="10">
          <h3 class="mb-0" style="font-size: 20px">Segments</h3>
        </a-col>
        <a-col :span="14" class="text-right">
          <a-button v-if="permissionCreate" type="primary" size="large"
            style="font-size: 16px; font-weight: 300; border-radius: 2px" @click="createSegment">
            <a-icon type="plus" /> Create new
          </a-button>
        </a-col>
      </a-row>
      <div style="padding: 24px; background-color: #fff">
        <a-card>
          <div class="d-flex align-items-center justify-content-between mb-4">
            <div style="flex-grow: 1">
              <a-input-search v-model="search" placeholder="Input search text" enter-button @change="tableUpdate" :loading="loading" />
            </div>
          </div>
          <a-table :columns="columns" :data-source="segments" :rowKey="(record, index) => index" :loading="loading"
            :pagination="pagination" @change="changePagination" class="segments__table operations-table">
            <template slot="name" slot-scope="text, record">
              <div>
                <span v-if="record.is_loading" class="mr-2"><a-icon class="text-primary" type="loading"></a-icon></span>
                <span>{{ record.name }}</span>
                <span v-if="record.type !== 'all'" class="main-segment">Auto</span>
              </div>
            </template>
            <template slot="actions" slot-scope="text, record">
              <div class="row-operations">
                <span :class="{ 'text-gray-5 icon-disabled': record.is_default || !permissionEdit || record.type !== 'all'}">
                  <a class="font-size-18 edit-icon text-primary" :class="{ 'text-gray-5 icon-disabled': record.is_default || !permissionEdit || record.type !== 'all' }" :disabled="record.is_default || !permissionEdit || record.type !== 'all'" @click="editSegment(record.id)">
                    <a-icon type="edit" />
                  </a>
                  <a-popconfirm placement="left" :disabled="record.is_default || !permissionEdit || record.type !== 'all'" title="Sure to delete this segment?" @confirm="() => deleteSegment(record.id)">
                    <a-icon class="font-size-18 text-danger delete-icon" :class="{ 'text-gray-5 icon-disabled': record.is_default || !permissionEdit || record.type !== 'all' }" type="delete" />
                  </a-popconfirm>
                </span>
              </div>
            </template>
          </a-table>
        </a-card>
      </div>
      <a-modal v-model="addSegmentsModal" title="Add segment" :footer="null" width="450px" :destroyOnClose="true">
        <SegmentsModal @closeModalFunction="closeAddSegmentsModal" @addNewSegment="handleAddSegment"/>
      </a-modal>
    </div>
  </template>
<script>
import ViewscreenService from '@/services/api/apiViewscreenService'
import SegmentsModal from '@/components/custom/modals/segmentsModal.vue'
import accessMix from '@/services/accessmix.js'
import { mapState } from 'vuex'
import debounce from 'lodash.debounce'

export default {
  name: 'index',
  components: { SegmentsModal },
  mixins: [accessMix],
  data: () => ({
    loading: false,
    segments: null,
    search: '',
    addSegmentsModal: false,
    pagination: {
      pageSize: 25,
      current: 1,
      total: 0,
    },
    columns: [
      {
        title: 'ID',
        dataIndex: 'id',
        width: '22%',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        width: '22%',
        scopedSlots: { customRender: 'name' },
      },
      {
        title: 'Users',
        dataIndex: 'count',
        width: '22%',
      },
      {
        title: 'Actions',
        width: '12%',
        scopedSlots: { customRender: 'actions' },
      },
    ],
  }),
  computed: {
    ...mapState(['user']),
    permissionCreate() {
      return this.checkPermissions(this.user.permissions, 'segments', 'create')
    },
    permissionEdit() {
      return this.checkPermissions(this.user.permissions, 'segments', 'edit')
    },
  },
  created() {
    this.getSegments()
  },
  methods: {
    async getSegments(segmentName) {
      this.loading = true
      try {
        const response = await ViewscreenService.getSegments(segmentName, this.pagination.current, this.pagination.pageSize)
        if (response.data.data) {
          this.segments = response.data.data
          this.pagination.total = response.data.meta.total
        }
      } finally {
        this.loading = false
      }
    },
    tableUpdate: debounce(function() {
      this.getSegments(this.search)
    }, 300),
    changePagination(pagination) {
      this.pagination = pagination
      this.getSegmentUsers(this.segmentId, this.search)
    },
    createSegment() {
      this.addSegmentsModal = true
    },
    closeAddSegmentsModal() {
      this.addSegmentsModal = false
    },
    handleAddSegment (newSegment) {
      this.closeAddSegmentsModal()
      this.segments.splice(1, 0, newSegment)
    },
    editSegment(segmentId) {
      this.$router.push(`/segments/edit/${segmentId}`)
    },
    async deleteSegment(segmentId) {
      this.loading = true
      try {
        const response = await ViewscreenService.deleteSegment(segmentId)
        if (response.data.success === true) {
          const deletedIndex = this.segments.findIndex(segment => segment.id === segmentId)
          console.log(deletedIndex)
          if (deletedIndex !== -1) {
            this.segments.splice(deletedIndex, 1)
          }
          this.$notification.success({
            message: 'Segment has been successfully deleted',
          })
        }
      } catch (error) {
        this.$notification.error({
          message: 'Error while deleting segments',
          description: error.message,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
  <style lang="scss">
  .segments {
    &__table {
      .ant-table-header-column {
        font-weight: bold;
      }

      .main-segment {
        font-size: 10px;
        color: #FF4D4F;
        margin-left: 8px;
      }

      .active-classes {

        &:hover,
        :focus {
          cursor: default;
          color: #a1a1c2 !important;
        }
      }
    }
    .ant-table-pagination.ant-pagination {
      margin: 16px auto 0px;
    }
  }
  </style>
